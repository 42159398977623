import React, { FC, SVGAttributes } from 'react';

export const TrashOutlined: FC<SVGAttributes<HTMLOrSVGElement>> = props => {
  return (
    <svg
      width="14px"
      height="16px"
      viewBox="0 0 14 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>16px - Trash - Pink@1.5x</title>
      <g
        id="UI-design"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Assets" transform="translate(-553.000000, -116.000000)">
          <g
            id="16px---Trash---Pink"
            transform="translate(552.000000, 116.000000)"
          >
            <rect id="Rectangle" x="0" y="0" width="16" height="16"></rect>
            <path
              d="M8.66666667,0.533333333 C9.84487413,0.533333333 10.8,1.4884592 10.8,2.66666667 L10.7993333,3.33333333 L13.8666667,3.33333333 C14.3084945,3.33333333 14.6666667,3.69150553 14.6666667,4.13333333 C14.6666667,4.57516113 14.3084945,4.93333333 13.8666667,4.93333333 L13.4173333,4.93333333 L12.8774603,13.4680848 C12.8095765,14.5406488 11.9572286,15.3866125 10.900653,15.4613023 L10.748387,15.4666667 L5.25161299,15.4666667 C4.12572621,15.4666667 3.19365604,14.5917233 3.12253968,13.4680848 L2.58133333,4.93333333 L2.13333333,4.93333333 C1.69150553,4.93333333 1.33333333,4.57516113 1.33333333,4.13333333 C1.33333333,3.69150553 1.69150553,3.33333333 2.13333333,3.33333333 L5.19933333,3.33333333 L5.2,2.66666667 C5.2,1.53968561 6.07387697,0.616809461 7.18097938,0.53868976 L7.33333333,0.533333333 Z M11.814,4.93333333 L4.18533333,4.93333333 L4.71934466,13.3670212 C4.73564216,13.6245217 4.93279975,13.8297789 5.18235105,13.8622003 L5.25161299,13.8666667 L10.748387,13.8666667 C11.0298587,13.8666667 11.2628762,13.6479308 11.2806553,13.3670212 L11.814,4.93333333 Z M8,6.33333333 C8.34189056,6.33333333 8.62367144,6.59069346 8.66218151,6.92225258 L8.66666667,7 L8.66666667,11.6666667 C8.66666667,12.0348565 8.36818983,12.3333333 8,12.3333333 C7.65810944,12.3333333 7.37632856,12.0759732 7.33781849,11.7444141 L7.33333333,11.6666667 L7.33333333,7 C7.33333333,6.63181017 7.63181017,6.33333333 8,6.33333333 Z M8.66666667,2.13333333 L7.33333333,2.13333333 C7.03878147,2.13333333 6.8,2.3721148 6.8,2.66666667 L6.8,3.33266667 L9.2,3.33266667 L9.2,2.66666667 C9.2,2.39477264 8.99654124,2.17039895 8.73356687,2.13748876 L8.66666667,2.13333333 Z"
              id="Combined-Shape"
              fill="currentColor"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
