import { AxiosResponse } from 'axios';
import { ProjectPayload } from 'interfaces';
import { IGetParams } from 'type';
import APIService from './base.service';

export default class ProjectService {
  static getProjects(params: IGetParams): Promise<AxiosResponse> {
    return APIService.get('projects', { params });
  }

  static getProjectById(id: string | undefined): Promise<AxiosResponse> {
    return APIService.get(`projects/${id}`);
  }

  static createProject(payload: ProjectPayload): Promise<AxiosResponse> {
    return APIService.post('projects', payload);
  }

  static updateProject(payload: ProjectPayload): Promise<AxiosResponse> {
    return APIService.put(`projects/${payload.id}`, payload);
  }

  static deleteProject(payload: { projectId: string }): Promise<AxiosResponse> {
    return APIService.delete(`projects/${payload.projectId}`);
  }

  static restoreProject(payload: {
    projectId: string;
  }): Promise<AxiosResponse> {
    return APIService.post(`projects/${payload.projectId}/restore`);
  }
}
