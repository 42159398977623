import React, { FC, SVGAttributes } from 'react';

export const ExternalIcon: FC<SVGAttributes<HTMLOrSVGElement>> = props => {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>16px - External - Pink@1.5x</title>
      <g
        id="UI-design"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Assets" transform="translate(-408.000000, -152.000000)">
          <g
            id="16px---External---Pink"
            transform="translate(408.000000, 152.000000)"
          >
            <rect id="Rectangle" x="0" y="0" width="16" height="16" />
            <path
              d="M8,0.533333333 C8.4418278,0.533333333 8.8,0.891505533 8.8,1.33333333 C8.8,1.77516113 8.4418278,2.13333333 8,2.13333333 L4,2.13333333 C2.96906847,2.13333333 2.13333333,2.96906847 2.13333333,4 L2.13333333,12 C2.13333333,13.0309315 2.96906847,13.8666667 4,13.8666667 L12,13.8666667 C13.0309315,13.8666667 13.8666667,13.0309315 13.8666667,12 L13.8666667,8 C13.8666667,7.5581722 14.2248389,7.2 14.6666667,7.2 C15.1084945,7.2 15.4666667,7.5581722 15.4666667,8 L15.4666667,12 C15.4666667,13.9145871 13.9145871,15.4666667 12,15.4666667 L4,15.4666667 C2.08541287,15.4666667 0.533333333,13.9145871 0.533333333,12 L0.533333333,4 C0.533333333,2.08541287 2.08541287,0.533333333 4,0.533333333 L8,0.533333333 Z M14,0.533333333 C14.8100176,0.533333333 15.4666667,1.18998237 15.4666667,2 L15.4666667,5 C15.4666667,5.4418278 15.1084945,5.8 14.6666667,5.8 C14.2248389,5.8 13.8666667,5.4418278 13.8666667,5 L13.8663333,3.26433333 L8.56568542,8.56568542 C8.25326599,8.87810486 7.74673401,8.87810486 7.43431458,8.56568542 C7.12189514,8.25326599 7.12189514,7.74673401 7.43431458,7.43431458 L12.7333333,2.13333333 L11,2.13333333 C10.5857864,2.13333333 10.2450983,1.81853355 10.2041303,1.41512869 L10.2,1.33333333 C10.2,0.891505533 10.5581722,0.533333333 11,0.533333333 L14,0.533333333 Z"
              id="Combined-Shape"
              fill="currentColor"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
