export const SCOPE_CONFIG = {
  ACTION: {
    READ: 'READ',
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
    RESTORE: 'RESTORE',
  },
  ENTITY: {
    USERS: 'users',
    SURVEYS: 'surveys',
    PROJECTS: 'projects',
    CATEGORIES: 'categories',
    QUESTIONS: 'questions',
  },
};
