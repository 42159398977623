import React, { FC, SVGAttributes } from 'react';

export const FilterOutlined: FC<SVGAttributes<HTMLOrSVGElement>> = props => {
  return (
    <svg
      width="10px"
      height="12px"
      viewBox="0 0 10 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>12px - Filter - Pink@1.5x</title>
      <g
        id="UI-design"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Assets" transform="translate(-625.000000, -258.000000)">
          <g
            id="12px---Filter---Pink"
            transform="translate(624.000000, 258.000000)"
          >
            <rect id="Rectangle" x="0" y="0" width="12" height="12"></rect>
            <path
              d="M9,0.6 L3,0.6 C2.1163444,0.6 1.4,1.3163444 1.4,2.2 L1.4,2.76325011 C1.4,3.5348777 1.74273983,4.26664149 2.33552056,4.76062543 L4.25607376,6.36108643 C4.3472708,6.43708396 4.4,6.54966301 4.4,6.66837495 L4.4,9.7 C4.4,10.2036117 4.63711065,10.677833 5.04,10.98 L5.64,11.43 C5.85204281,11.5890321 6.10994649,11.675 6.375,11.675 C7.05154882,11.675 7.6,11.1265488 7.6,10.45 L7.6,6.66837495 C7.6,6.54966301 7.6527292,6.43708396 7.74392624,6.36108643 L9.66447944,4.76062543 C10.2572602,4.26664149 10.6,3.5348777 10.6,2.76325011 L10.6,2.2 C10.6,1.3163444 9.8836556,0.6 9,0.6 Z M3,1.8 L9,1.8 C9.2209139,1.8 9.4,1.9790861 9.4,2.2 L9.4,2.76325011 C9.4,3.17874189 9.21544779,3.57276854 8.89625816,3.8387599 L6.97570496,5.4392209 C6.61091682,5.74321102 6.4,6.1935272 6.4,6.66837495 L6.4,10.45 C6.4,10.4638071 6.38880712,10.475 6.375,10.475 C6.36959074,10.475 6.3643274,10.4732456 6.36,10.47 L5.76,10.02 C5.65927766,9.94445825 5.6,9.82590292 5.6,9.7 L5.6,6.66837495 C5.6,6.1935272 5.38908318,5.74321102 5.02429504,5.4392209 L3.10374184,3.8387599 C2.78455221,3.57276854 2.6,3.17874189 2.6,2.76325011 L2.6,2.2 C2.6,1.9790861 2.7790861,1.8 3,1.8 Z"
              id="Rectangle"
              fill="currentColor"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
