import React from 'react';

export const Clock = props => {
  const { style, className } = props;
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>16px - Time - Pink@1.5x</title>
      <g
        id="UI-design"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Assets" transform="translate(-120.000000, -116.000000)">
          <g
            id="16px---Time---Pink"
            transform="translate(120.000000, 116.000000)"
          >
            <rect id="Rectangle" x="0" y="0" width="16" height="16"></rect>
            <path
              d="M8,0.533333333 C12.1237261,0.533333333 15.4666667,3.87627387 15.4666667,8 C15.4666667,12.1237261 12.1237261,15.4666667 8,15.4666667 C3.87627387,15.4666667 0.533333333,12.1237261 0.533333333,8 C0.533333333,3.87627387 3.87627387,0.533333333 8,0.533333333 Z M8,2.13333333 C4.75992947,2.13333333 2.13333333,4.75992947 2.13333333,8 C2.13333333,11.2400705 4.75992947,13.8666667 8,13.8666667 C11.2400705,13.8666667 13.8666667,11.2400705 13.8666667,8 C13.8666667,4.75992947 11.2400705,2.13333333 8,2.13333333 Z M8,3.33333333 C8.4418278,3.33333333 8.8,3.69150553 8.8,4.13333333 L8.79933333,7.06633333 L11.0666667,7.06666667 C11.4745077,7.06666667 11.8110682,7.37185481 11.8604335,7.76631637 L11.8666667,7.86666667 C11.8666667,8.30849447 11.5084945,8.66666667 11.0666667,8.66666667 L8,8.66666667 C7.5581722,8.66666667 7.2,8.30849447 7.2,7.86666667 L7.2,4.13333333 C7.2,3.69150553 7.5581722,3.33333333 8,3.33333333 Z"
              id="Shape"
              fill="#C94397"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
