import React, { FC, SVGAttributes } from 'react';

export const PenFilled: FC<SVGAttributes<HTMLOrSVGElement>> = props => {
  return (
    <svg
      width="14px"
      height="14px"
      viewBox="0 0 14 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>16px - Edit - Pink@1.5x</title>
      <g
        id="UI-design"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Assets" transform="translate(-229.000000, -153.000000)">
          <g
            id="16px---Edit---Pink"
            transform="translate(228.000000, 152.000000)"
          >
            <rect id="Rectangle" x="0" y="0" width="16" height="16"></rect>
            <path
              d="M4.23276775,13.4355464 L4.2492084,14.109951 L4.2492084,14.109951 L1.62521281,14.5730401 C1.53753045,14.5885145 1.45042278,14.5292818 1.4306524,14.4407403 C1.4259262,14.419574 1.42539746,14.3978848 1.42909668,14.376924 L1.8921858,11.7529284 L2.56659034,11.769369 C2.83318253,11.775868 3.04775745,11.9904429 3.05425645,12.2570351 L3.05909168,12.455379 C3.06559067,12.7219712 3.2801656,12.9365461 3.54675778,12.9430451 L3.74510165,12.9478803 C4.01169383,12.9543793 4.22626876,13.1689542 4.23276775,13.4355464 Z M12.6673521,5.69180732 L4.81041798,13.5487414 L4.79397732,12.8743368 C4.78747833,12.6077447 4.57290341,12.3931697 4.30631122,12.3866707 L4.10796735,12.3818355 C3.84137517,12.3753365 3.62680025,12.1607616 3.62030125,11.8941694 L3.61546602,11.6958255 C3.60896703,11.4292334 3.3943921,11.2146584 3.12779992,11.2081594 L2.45339537,11.1917188 L2.45339537,11.1917188 L10.3103294,3.33478472 L12.6673521,5.69180732 Z M14.3229347,2.85771336 C14.6483716,3.18315027 14.6609283,3.6982311 14.3509808,4.00817859 L13.2285616,5.13059774 L10.871539,2.77357514 L11.9939582,1.65115599 C12.3039057,1.3412085 12.8189865,1.35376515 13.1444234,1.67920206 L14.3229347,2.85771336 Z"
              id="Shape"
              fill="currentColor"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
