import React, { FC, SVGAttributes } from 'react';

export const InternalIcon: FC<SVGAttributes<HTMLOrSVGElement>> = props => {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>16px - Internal - Pink@1.5x</title>
      <g
        id="UI-design"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Assets" transform="translate(-372.000000, -152.000000)">
          <g
            id="16px---Internal---Pink"
            transform="translate(372.000000, 152.000000)"
          >
            <rect id="Rectangle" x="0" y="0" width="16" height="16" />
            <path
              d="M8,0.533333333 C8.4418278,0.533333333 8.8,0.891505533 8.8,1.33333333 C8.8,1.77516113 8.4418278,2.13333333 8,2.13333333 L4,2.13333333 C2.96906847,2.13333333 2.13333333,2.96906847 2.13333333,4 L2.13333333,12 C2.13333333,13.0309315 2.96906847,13.8666667 4,13.8666667 L12,13.8666667 C13.0309315,13.8666667 13.8666667,13.0309315 13.8666667,12 L13.8666667,8 C13.8666667,7.5581722 14.2248389,7.2 14.6666667,7.2 C15.1084945,7.2 15.4666667,7.5581722 15.4666667,8 L15.4666667,12 C15.4666667,13.9145871 13.9145871,15.4666667 12,15.4666667 L4,15.4666667 C2.08541287,15.4666667 0.533333333,13.9145871 0.533333333,12 L0.533333333,4 C0.533333333,2.08541287 2.08541287,0.533333333 4,0.533333333 L8,0.533333333 Z M14.5656854,1.43431458 C14.8781049,1.74673401 14.8781049,2.25326599 14.5656854,2.56568542 L9.59833333,7.53233333 L11.3333333,7.53333333 C11.7475469,7.53333333 12.088235,7.84813312 12.129203,8.25153798 L12.1333333,8.33333333 C12.1333333,8.77516113 11.7751611,9.13333333 11.3333333,9.13333333 L8.33333333,9.13333333 C7.5233157,9.13333333 6.86666667,8.4766843 6.86666667,7.66666667 L6.86666667,4.66666667 C6.86666667,4.22483887 7.22483887,3.86666667 7.66666667,3.86666667 C8.10849447,3.86666667 8.46666667,4.22483887 8.46666667,4.66666667 L8.46633333,6.40033333 L13.4343146,1.43431458 C13.746734,1.12189514 14.253266,1.12189514 14.5656854,1.43431458 Z"
              id="Combined-Shape"
              fill="currentColor"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
