import React, { FC, SVGAttributes } from 'react';

export const ListIcon: FC<SVGAttributes<HTMLOrSVGElement>> = props => {
  return (
    <svg
      width="14px"
      height="14px"
      viewBox="0 0 14 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>16px - List - Pink@1.5x</title>
      <g
        id="UI-design"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Assets" transform="translate(-445.000000, -153.000000)">
          <g
            id="16px---List---Pink"
            transform="translate(444.000000, 152.000000)"
          >
            <rect id="Rectangle" x="0" y="0" width="16" height="16" />
            <path
              d="M6.8,2.53333333 L13.8666667,2.53333333 C14.3084945,2.53333333 14.6666667,2.89150553 14.6666667,3.33333333 C14.6666667,3.77516113 14.3084945,4.13333333 13.8666667,4.13333333 L6.8,4.13333333 C6.3581722,4.13333333 6,3.77516113 6,3.33333333 C6,2.89150553 6.3581722,2.53333333 6.8,2.53333333 Z M6.8,7.2 L13.8666667,7.2 C14.3084945,7.2 14.6666667,7.5581722 14.6666667,8 C14.6666667,8.4418278 14.3084945,8.8 13.8666667,8.8 L6.8,8.8 C6.3581722,8.8 6,8.4418278 6,8 C6,7.5581722 6.3581722,7.2 6.8,7.2 Z M6.8,11.8666667 L13.8666667,11.8666667 C14.3084945,11.8666667 14.6666667,12.2248389 14.6666667,12.6666667 C14.6666667,13.1084945 14.3084945,13.4666667 13.8666667,13.4666667 L6.8,13.4666667 C6.3581722,13.4666667 6,13.1084945 6,12.6666667 C6,12.2248389 6.3581722,11.8666667 6.8,11.8666667 Z M3,1.66666667 C3.92047458,1.66666667 4.66666667,2.41285875 4.66666667,3.33333333 C4.66666667,4.25380792 3.92047458,5 3,5 C2.07952542,5 1.33333333,4.25380792 1.33333333,3.33333333 C1.33333333,2.41285875 2.07952542,1.66666667 3,1.66666667 Z M3,6.33333333 C3.92047458,6.33333333 4.66666667,7.07952542 4.66666667,8 C4.66666667,8.92047458 3.92047458,9.66666667 3,9.66666667 C2.07952542,9.66666667 1.33333333,8.92047458 1.33333333,8 C1.33333333,7.07952542 2.07952542,6.33333333 3,6.33333333 Z M3,11 C3.92047458,11 4.66666667,11.7461921 4.66666667,12.6666667 C4.66666667,13.5871412 3.92047458,14.3333333 3,14.3333333 C2.07952542,14.3333333 1.33333333,13.5871412 1.33333333,12.6666667 C1.33333333,11.7461921 2.07952542,11 3,11 Z"
              id="Shape"
              fill="currentColor"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
