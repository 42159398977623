import React from 'react';

export const WarningIcon = props => {
  const { style, className } = props;
  return (
    <svg
      className={className}
      style={style}
      width="40px"
      height="14px"
      viewBox="0 0 4 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>20px - Exclamation Mark - White@1.5x</title>
      <g
        id="Design"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Icons" transform="translate(-1692.000000, -331.000000)">
          <g
            id="Notification---Attention"
            transform="translate(1684.000000, 328.000000)"
          >
            <rect id="Rectangle" x="0" y="0" width="20" height="20"></rect>
            <path
              d="M10,14.1666667 C10.6903559,14.1666667 11.25,14.7263107 11.25,15.4166667 C11.25,16.1070226 10.6903559,16.6666667 10,16.6666667 C9.30964406,16.6666667 8.75,16.1070226 8.75,15.4166667 C8.75,14.7263107 9.30964406,14.1666667 10,14.1666667 Z M10.5291739,3.33333333 C11.1919156,3.33333333 11.7291739,3.87059163 11.7291739,4.53333333 C11.7291739,4.56659462 11.727791,4.59984152 11.7250288,4.63298791 L11.0830455,12.3367876 C11.0361055,12.9000672 10.5652321,13.3333333 10,13.3333333 C9.4347679,13.3333333 8.96389449,12.9000672 8.91695452,12.3367876 L8.27497121,4.63298791 C8.21993351,3.97253548 8.71071912,3.39251613 9.37117155,3.33747842 C9.40431794,3.33471622 9.43756484,3.33333333 9.47082612,3.33333333 L10.5291739,3.33333333 Z"
              id="Combined-Shape"
              fill="#FFFFFF"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
