import React from 'react';
export const ErrorIcon = props => {
  const { style, className } = props;
  return (
    <svg
      width="40px"
      height="12px"
      viewBox="0 0 12 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Design"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Icons" transform="translate(-1652.000000, -332.000000)">
          <g
            id="Notification---Cross"
            transform="translate(1648.000000, 328.000000)"
          >
            <rect id="Rectangle" x="0" y="0" width="44" height="44"></rect>
            <path
              d="M15.3033009,4.69669914 C15.6938252,5.08722343 15.6938252,5.72038841 15.3033009,6.1109127 L11.4135065,9.99929289 L15.3033009,13.8890873 C15.6938252,14.2796116 15.6938252,14.9127766 15.3033009,15.3033009 C14.9127766,15.6938252 14.2796116,15.6938252 13.8890873,15.3033009 L9.99929289,11.4135065 L6.1109127,15.3033009 C5.72038841,15.6938252 5.08722343,15.6938252 4.69669914,15.3033009 C4.30617485,14.9127766 4.30617485,14.2796116 4.69669914,13.8890873 L8.58507933,9.99929289 L4.69669914,6.1109127 C4.30617485,5.72038841 4.30617485,5.08722343 4.69669914,4.69669914 C5.08722343,4.30617485 5.72038841,4.30617485 6.1109127,4.69669914 L9.99929289,8.58507933 L13.8890873,4.69669914 C14.2796116,4.30617485 14.9127766,4.30617485 15.3033009,4.69669914 Z"
              id="Combined-Shape"
              fill="#FFFFFF"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
