import React, { FC, SVGAttributes } from 'react';

export const PlusIcon: FC<SVGAttributes<HTMLOrSVGElement>> = props => {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>16px - Circle - Add - Pink@1.5x</title>
      <g
        id="UI-design"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Assets" transform="translate(-120.000000, -152.000000)">
          <g
            id="16px---Circle---Add---Pink"
            transform="translate(120.000000, 152.000000)"
          >
            <rect id="Rectangle" x="0" y="0" width="16" height="16"></rect>
            <path
              d="M8,0 C12.418278,0 16,3.581722 16,8 C16,12.418278 12.418278,16 8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 Z M8,2.66666667 C7.5581722,2.66666667 7.2,3.02483887 7.2,3.46666667 L7.19966667,7.19966667 L3.46666667,7.2 C3.02483887,7.2 2.66666667,7.5581722 2.66666667,8 C2.66666667,8.4418278 3.02483887,8.8 3.46666667,8.8 L7.19966667,8.79966667 L7.2,12.5333333 C7.2,12.9751611 7.5581722,13.3333333 8,13.3333333 C8.4418278,13.3333333 8.8,12.9751611 8.8,12.5333333 L8.79966667,8.79966667 L12.5333333,8.8 C12.9751611,8.8 13.3333333,8.4418278 13.3333333,8 C13.3333333,7.5581722 12.9751611,7.2 12.5333333,7.2 L8.79966667,7.19966667 L8.8,3.46666667 C8.8,3.02483887 8.4418278,2.66666667 8,2.66666667 Z"
              id="Combined-Shape"
              fill="currentColor"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
