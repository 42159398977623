import React from 'react';

export const Refresh = props => {
  const { style, className } = props;
  return (
    <svg
      width="14px"
      height="16px"
      viewBox="0 0 14 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>16px - Refresh - Pink@1.5x</title>
      <g
        id="UI-design"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Assets" transform="translate(-49.000000, -116.000000)">
          <g
            id="16px---Refresh---Pink"
            transform="translate(48.000000, 116.000000)"
          >
            <rect id="Rectangle" x="0" y="0" width="16" height="16"></rect>
            <path
              d="M8.38979589,0.875996281 C8.70393318,0.623283672 9.16461825,0.642723104 9.45620972,0.934314575 L9.51452801,0.999271599 C9.76724062,1.31340888 9.74780119,1.77409395 9.45620972,2.06568542 L8.31241825,3.20781916 C11.5545912,3.37048546 14.1333333,6.05076065 14.1333333,9.33333333 C14.1333333,12.7206798 11.3873465,15.4666667 8,15.4666667 C4.61265353,15.4666667 1.86666667,12.7206798 1.86666667,9.33333333 C1.86666667,8.89150553 2.22483887,8.53333333 2.66666667,8.53333333 C3.10849447,8.53333333 3.46666667,8.89150553 3.46666667,9.33333333 C3.46666667,11.8370242 5.49630913,13.8666667 8,13.8666667 C10.5036909,13.8666667 12.5333333,11.8370242 12.5333333,9.33333333 C12.5333333,6.9425374 10.682603,4.9840027 8.33565062,4.8122376 L9.45620972,5.93431458 L9.51452801,5.9992716 C9.76724062,6.31340888 9.74780119,6.77409395 9.45620972,7.06568542 C9.14379028,7.37810486 8.6372583,7.37810486 8.32483887,7.06568542 L6.29624339,5.03708995 L6.21831564,4.95292176 C5.72477322,4.37668586 5.75074914,3.5084043 6.29624339,2.96291005 L8.32483887,0.934314575 Z"
              id="Combined-Shape"
              fill="#C94397"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
