import React from 'react';
import { VerifyPageStyled } from './style';
import { VerifyForm } from '..';

const VerifyPage = () => {
  return (
    <VerifyPageStyled>
      <VerifyForm />
    </VerifyPageStyled>
  );
};

export default VerifyPage;
