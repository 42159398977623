import styled from 'styled-components';

export const DisplayQuestionListWrapper = styled.div`
  padding-left: 2rem;
  .ant-btn {
    width: 100%;
    margin-top: 1.5rem;
  }
  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  > div.line {
    border-bottom: 1px solid #f3eef3;
    width: 100%;
    margin: 1rem 0;
  }
`;
