import React from 'react';

export const CheckIcon = props => {
  const { style, className } = props;
  return (
    <svg
      className={className}
      style={{ ...style }}
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Icon-/-Check-Mark-/-Simple"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <rect id="Rectangle" x="0" y="0" width="24" height="24" />
        <path
          d="M18.3733647,4.42219116 C18.9315588,4.69506162 19.1832902,5.34372005 18.9727486,5.91433676 L18.9244305,6.02728508 L13.1248432,17.89114 C12.3486783,19.4788922 10.4323449,20.1368137 8.84459275,19.3606488 C8.56725457,19.2250734 8.31136269,19.0502629 8.08465936,18.8419225 L7.92022568,18.6794696 L5.22635169,15.8185409 C4.77202146,15.3360364 4.79486124,14.5765818 5.27736575,14.1222516 C5.7277033,13.69821 6.41929515,13.6898366 6.8788932,14.0828988 L6.9736551,14.1732657 L9.6675291,17.0341943 C9.73366196,17.1044283 9.81195268,17.1621218 9.89862086,17.2044891 C10.2650252,17.3836041 10.7014232,17.2572332 10.9190119,16.9245291 L10.9686835,16.8371119 L16.7682708,4.97325697 C17.0593326,4.3778499 17.7779576,4.13112934 18.3733647,4.42219116 Z"
          id="Path"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
