import React from 'react';

export const Chat = props => {
  const { style, className } = props;
  return (
    <svg
      width="16px"
      height="15px"
      viewBox="0 0 16 15"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>16px - Chat - Pink@1.5x</title>
      <g
        id="UI-design"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Assets" transform="translate(-84.000000, -117.000000)">
          <g
            id="16px---Chat---Pink"
            transform="translate(84.000000, 116.000000)"
          >
            <rect id="Rectangle" x="0" y="0" width="16" height="16"></rect>
            <path
              d="M12.5288938,1.6 C14.0752911,1.6 15.3288938,2.8536027 15.3288938,4.4 C15.3288938,4.46213602 15.3268255,4.52425483 15.3226923,4.58625323 L14.8869201,11.122835 C14.8089946,12.2917182 13.8381445,13.2 12.6666667,13.2 L12.1333333,13.2 L12.1333333,13.9020139 C12.1333333,14.6768134 11.5325429,15.3112908 10.77141,15.364998 L10.6666667,15.3686806 C10.3044743,15.3686806 9.95509122,15.2346635 9.68581082,14.9924418 L7.69333333,13.2 L3.87110618,13.2 C2.44307293,13.2 1.25196263,12.1274031 1.08976563,10.7231583 L1.07730774,10.5862532 L0.677307742,4.58625323 C0.574442924,3.04328096 1.74188068,1.70906638 3.28485295,1.60620156 C3.34685136,1.60206833 3.40897016,1.6 3.47110618,1.6 Z M12.5288938,3.2 L3.43117266,3.20066464 L3.39128337,3.20265781 C2.73000954,3.24674273 2.22967907,3.81854898 2.27376399,4.47982281 L2.67376399,10.4798228 C2.71578782,11.1101802 3.23934954,11.6 3.87110618,11.6 L7.74427856,11.6 C8.10647088,11.6 8.455854,11.7340171 8.72513441,11.9762388 L10.5333333,13.6026667 L10.5333333,13.0666667 C10.5333333,12.2918672 11.1341238,11.6573898 11.8952567,11.6036825 L12,11.6 L12.6666667,11.6 C12.9958024,11.6 13.2685701,11.3448113 13.2904639,11.0164046 L13.726236,4.47982281 L13.7288938,4.4 C13.7288938,3.7372583 13.1916355,3.2 12.5288938,3.2 Z M10.6666667,7.73333333 C11.0348565,7.73333333 11.3333333,8.03181017 11.3333333,8.4 C11.3333333,8.76818983 11.0348565,9.06666667 10.6666667,9.06666667 L5.33333333,9.06666667 C4.9651435,9.06666667 4.66666667,8.76818983 4.66666667,8.4 C4.66666667,8.03181017 4.9651435,7.73333333 5.33333333,7.73333333 L10.6666667,7.73333333 Z M11.3333333,5.06666667 C11.7015232,5.06666667 12,5.3651435 12,5.73333333 C12,6.10152317 11.7015232,6.4 11.3333333,6.4 L4.66666667,6.4 C4.29847683,6.4 4,6.10152317 4,5.73333333 C4,5.3651435 4.29847683,5.06666667 4.66666667,5.06666667 L11.3333333,5.06666667 Z"
              id="Shape"
              fill="#C94397"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
