import React, { FC, SVGAttributes } from 'react';

export const FileIconOutlined: FC<SVGAttributes<HTMLOrSVGElement>> = props => {
  return (
    <svg
      width="12px"
      height="16px"
      viewBox="0 0 12 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title> File - Pink</title>
      <g
        id="UI-design"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Assets" transform="translate(-518.000000, -116.000000)">
          <g
            id="16px---File---Pink"
            transform="translate(516.000000, 116.000000)"
          >
            <rect id="Rectangle" x="0" y="0" width="16" height="16" />
            <path
              d="M8.70883341,0.533333333 C9.34196374,0.533333333 9.94238531,0.814555755 10.3477055,1.30093995 L12.9722054,4.45033986 C13.2916987,4.83373186 13.4666667,5.31700204 13.4666667,5.81606658 L13.4666667,13.3333333 C13.4666667,14.5115408 12.5115408,15.4666667 11.3333333,15.4666667 L4.66666667,15.4666667 C3.4884592,15.4666667 2.53333333,14.5115408 2.53333333,13.3333333 L2.53333333,2.66666667 C2.53333333,1.4884592 3.4884592,0.533333333 4.66666667,0.533333333 Z M6.53233333,2.13233333 L4.66666667,2.13333333 C4.3721148,2.13333333 4.13333333,2.3721148 4.13333333,2.66666667 L4.13333333,13.3333333 C4.13333333,13.6278852 4.3721148,13.8666667 4.66666667,13.8666667 L11.3333333,13.8666667 C11.6278852,13.8666667 11.8666667,13.6278852 11.8666667,13.3333333 L11.8663333,8.79933333 L8.66666667,8.8 C7.52908704,8.8 6.59946934,7.90960918 6.53671043,6.78772441 L6.53333333,6.66666667 L6.53233333,2.13233333 Z M8.80249691,2.14161788 L8.70883341,2.13333333 L8.13233333,2.13233333 L8.13333333,6.66666667 C8.13333333,6.9385607 8.3367921,7.16293439 8.59976647,7.19584458 L8.66666667,7.2 L11.8663333,7.19933333 L11.8666667,5.81606658 C11.8666667,5.72249198 11.8420618,5.63113846 11.7960628,5.55087424 L11.7430513,5.4746349 L9.11855142,2.32523499 C9.01722138,2.20363894 8.86711599,2.13333333 8.70883341,2.13333333 Z"
              id="Combined-Shape"
              fill="#C94397"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
